/*--------------------------------------------------------------
# Scroll down animation
--------------------------------------------------------------*/

#scroll-down-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%,-40%);
      
  }
  
  .mouse {
    margin: 0 auto;
    display: block;
    border-radius: 38px;
    border: 2px solid #fff;
    height: 50px;
    width: 30px;
    position: relative;
  }
  
  
  
  .move {
    position: absolute;
    background-color: #fff;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    left: 50%;  
    transform: translateX(-50%);
    animation: move 2s linear infinite;
  }
  
  @keyframes move {
    0% {
      transform: translate(-50%,5px);
      opacity: 0;
    }
    50% {
      transform: translate(-50%,15px);
      opacity: 1;
    }
    100% {
      transform: translate(-50%,30px);
      opacity: 0;
    }
  }
  
  