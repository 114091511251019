/* Load Settings */
.no-scroll-y {
	overflow-y: hidden;
}

#preloader-section{
    	box-sizing: border-box;
    	margin: 0;
    	padding: 0;
}


/* Preloader */
.ctn-preloader {
	-webkit-box-align: center;
	        align-items: center;
  
  /* Show or Hide cursor when hover of Preloader*/
  cursor: none;  
	display: -webkit-box;
	display: flex;
  height: 100%;
  -webkit-box-pack: center;
          justify-content: center;
	position: fixed;
	left: 0;
  top: 0;
	width: 100%;
  z-index: 9999;
}

.ctn-preloader .animation-preloader {
	position: absolute;
  z-index: 100;
}

/* Spinner loading */
.ctn-preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
          animation: spinner 1s infinite linear;
	border-radius: 50%;
  border: 5px solid rgba(41, 40, 40, 0.2);
 border-top-color: #1a1919; /* It is not identified in alphabetical order so that it does not overwrite it */ 
   height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

/* Text Loading */
.ctn-preloader .animation-preloader .txt-loading {
  font: italic 30px 'Montserrat', sans-serif;
	text-align: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  -webkit-animation: letters-loading 4s infinite;
          animation: letters-loading 4s infinite;
  color: #000000;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
	color: rgba(0, 0, 0, 0.2);
	position: relative;
}

/* Letters Animation */
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(10):before {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(11):before {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(12):before {
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(13):before {
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(14):before {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(15):before {
  -webkit-animation-delay: 2.8s;
          animation-delay: 2.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(16):before {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

/* Loader section Settings */
.ctn-preloader .loader-section {
background: white;
  
  height: 100%;
	position: fixed;
  top: 0;
  width: 100%;
}

.ctn-preloader .loader-section.section-left {
  left: 0;
}

.ctn-preloader .loader-section.section-right {
  right: 0;
}

/* Fade effect in loading animation */
.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

/* Curtain effect */
.loaded .loader-section.section-left {
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  -webkit-transition: 3.5s 0.1s all cubic-bezier(0.1, 0.1, 0.1, 2.000);
  transition: 3.5s 0.1s all cubic-bezier(0.1, 0.1, 0.1, 2.000);
}

.loaded .loader-section.section-right {
  -webkit-transform: translateX(101%);
          transform: translateX(101%);
  -webkit-transition: 3.5s 0.1s all cubic-bezier(0.1, 0.1, 0.1, 2.000);
  transition: 3.5s 0.1s all cubic-bezier(0.1, 0.1, 0.1, 2.000);
}

/* Preloader Animation */
@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotateZ(90deg);
		        transform: rotateZ(90deg);
	}
}
@keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		        transform: rotateZ(360deg);
	}
}

/* Animation of the letters loading from the preloader */
@-webkit-keyframes letters-loading {
  0%,
  75%,
  100% {
  	opacity: 0;
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
  	opacity: 0;
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

/* Backward laptop size (laptop, tablet, cell phone) */
@media screen and (max-width: 767px) {
	/* Preloader */
	/* Spinner Loading */	
	.ctn-preloader .animation-preloader .spinner {
		height: 8em;
		width: 8em;
	}

	/* Text Loading */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 3.5em 'Montserrat', sans-serif;
	}
}

@media screen and (max-width: 500px) {
	/* Prelaoder */
	/* Spinner Loading */
	.ctn-preloader .animation-preloader .spinner {
		height: 7em;
		width: 7em;
	}

	/* Text Loading */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 2em 'Montserrat', sans-serif;
	}
}
