@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light|Source+Sans+Pro:400,700");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: #272829;
}

a {
  color: rgb(77, 174, 81);
}

a:hover {
  color: #0ab35e;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  background: rgb(77, 174, 81);
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 50;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 7px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: #3a9c3d;
  transition: background 0.2s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  display: none;
}

/*--------------------------------------------------------------
# perosnal Section
--------------------------------------------------------------*/
#perosnal {
  width: 100%;
  height: 100vh;
  background: url("../img/bg.jpg") top center;
  background-size: cover;
}

#perosnal::before {
  content: "";
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#perosnal .perosnal-container {
  position: relative;
  z-index: 2;
  min-width: 300px;
  padding-top: 80px;
}

#perosnal h1 {
  margin: 0 0 10px 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#perosnal p {
  color: #fff;
  padding-bottom: 25px;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

#perosnal p span {
  color: #fff;
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 3px solid #0e7c45;
}

#personal .perosnal-container h5 {
  font-size: 40px;
}

.head-title {
  color: #fff;
  font-size: 80px;
  letter-spacing: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.script-font {
  font-family: "Shadows Into Light", cursive !important;
  color: #fff;
  font-size: 45px;
}

.social-icon li {
  display: inline-block;
}

.social-icon li a {
  color: #000;
  background: #fff;
  width: 35px;
  height: 35px;
  line-height: 38px;
  display: block;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  margin: 15px 6px 12px 6px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon {
  margin-left: -40px;
  margin-top: -25px;
}

.social-icon li a:hover {
  color: #fff;
}

.social-icon li .facebook:hover {
  background: #3b5999;
}

.social-icon li .dribbble:hover {
  background: #ea4c89;
}

.social-icon li .medium:hover {
  background: #000000;
}

.social-icon li .instagram:hover {
  background: #d6274d;
}

.social-icon li .github:hover {
  background: #333;
}

.social-icon li .google:hover {
  background: #ce332a;
}

.social-icon li .linkedin:hover {
  background: #0072b1;
}

.CV {
  animation: float 6s ease-in-out infinite;
}

@media (min-width: 1024px) {
  #perosnal {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #perosnal {
    min-height: 100vh;
  }

  #perosnal h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #perosnal h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background: #f5f8fd;
}

.section-title {
  padding-bottom: 30px;
  padding-top: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #173b6c;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #0e7c45;
  bottom: 0;
  left: 0;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 2px;
  color: rgb(77, 174, 81);
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #050d18;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #dce8f8;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #860792;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #050d18;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #1f5297;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #050d18;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #1f5297;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-filter {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-filter li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-filter li:hover,
.portfolio #portfolio-filter li.filter-active {
  color: #4dae51;
}

.portfolio #portfolio-filter li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  background: rgb(77, 174, 81);
  transition: 0.3s;
  width: 100%;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: rgb(46, 141, 49);
}

.portfolio .portfolio-wrap .portfolio-links a+a {
  border-left: 1px solid rgb(46, 141, 49);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-bottom: 130px;
}

.contact .info {
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info i {
  font-size: 20px;
  color: #0c7440;
  float: left;
  width: 44px;
  height: 44px;
  background: #dff3fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #050d18;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #173b6c;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #4dae51;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #4dae51;
  color: #fff;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #0e7c45 !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

@media (max-width: 768px) {
  .portfolio-details .portfolio-description h2 {
    width: 100%;
  }

  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/* Footer Area Start */
.footer-area {
  background: #1c1c20;
}

.footer-text p {
  font-size: 18px;
  margin-top: 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
}

.navbar {
  transition: all 0.4s;
}

.navbar .nav-link {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-item .active,
.navbar .nav-link:hover,
.navbar .nav-link:focus {
  color: #0ca358;
  text-decoration: none;
}

.navbar .navbar-brand {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 8px;
}

/* Change navbar styling on scroll */
.navbar.active {
  background: #fff;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.active .nav-link {
  color: #555;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
  color: #0ca358;
  text-decoration: none;
}

.navbar.active .navbar-brand {
  color: #555;
  text-transform: uppercase;
  letter-spacing: 8px;
}

/* Change navbar styling on small viewports */
@media (max-width: 991.98px) {
  .navbar {
    background: #fff;
  }

  .navbar .navbar-brand,
  .navbar .nav-link {
    color: #555;
  }

  .nav-link {
    margin: 10px 0 5px 0;
    border-bottom: 1px solid rgb(189, 189, 189);
  }
}