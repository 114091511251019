/* skills.css */

/* Transparent background and size for the skills cards */
.icon-box {
    background: transparent;
    width: 90px; /* Adjusted to match image size */
    height: 90px; /* Adjusted to match image size */
    margin: 10px 0; /* Added margin for spacing */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease; /* Add transition for smooth hover effect */
  }
  
  /* Hover effect */
  .icon-box:hover {
    transform: scale(1.1); /* Increase size on hover */
  }
  